export const SET_LOGGEDIN_USER = 'SET_USER';
export const SET_LOGGEDOUT_USER = 'SET_LOGGEDOUT_USER';

export const FETCH_SUBSCRIBED_USERS_REQUEST = 'FETCH_SUBSCRIBED_USERS_REQUEST'
export const FETCH_SUBSCRIBED_USERS_SUCCESS = 'FETCH_SUBSCRIBED_USERS_SUCCESS'
export const FETCH_SUBSCRIBED_USERS_FAILURE = 'FETCH_SUBSCRIBED_USERS_FAILURE'

export const DELETE_SUBSCRIBED_USER = 'DELETE_SUBSCRIBED_USER';

export const REFRESH_STATE = 'REFRESH_STATE';

export const SET_FORGET_PASSWORD_USER_EMAIL = 'SET_FORGET_PASSWORD_USER_EMAIL';
export const RESET_FORGET_PASSWORD_USER_EMAIL = 'RESET_FORGET_PASSWORD_USER_EMAIL';

export const SET_USER_SEARCH = 'SET_USER_SEARCH';
export const SET_IS_SIGNING = 'SET_IS_SIGNING';
export const RESET_IS_SIGNING = 'RESET_IS_SIGNING';
export const SET_USER_FILTER = 'SET_USER_FILTER';

export const FETCH_ENROLLMENTLIST_REQUEST = 'FETCH_ENROLLMENTLIST_REQUEST'
export const FETCH_ENROLLMENTLIST_SUCCESS = 'FETCH_ENROLLMENTLIST_SUCCESS'
export const FETCH_ENROLLMENTLIST_FAILURE = 'FETCH_ENROLLMENTLIST_FAILURE'
