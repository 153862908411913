export const enrollmentStatus = {
  0: { text: 'Pending', color: 'warning' },
  1: { text: 'Approved', color: 'success'},
  2: { text: 'Declined', color: 'danger'},
  3: { text: 'Cancelled', color: 'danger'},
};

export const paymentStatus = {
  0: { text: 'Pending', color: 'warning' },
  1: { text: 'Completed', color: 'success' },
};

export const instructorPaymentStatus = {
  0: { text: 'Pending', color: 'warning' },
  1: { text: 'In Progress', color: 'warning' },
  2: { text: 'Completed', color: 'success' },
};
