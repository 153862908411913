/*eslint-disable*/
import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Badge,
  Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import Header from 'components/Headers/Header.jsx';

import useViewport from 'hooks/useViewport';

import { selectUserToken } from 'redux/user/selectors';
import { selectUser } from 'redux/user/selectors';
import { fetchEnrollmentListThunkAction } from 'redux/user/actions';
import NewPagination from 'components/NewPagination/NewPagination';
import EnrolledCoursesModal from 'components/Modals/EnrolledCoursesModal';

const EnrolledCourses = () => {
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);

  const { width } = useViewport();

  const dispatch = useDispatch();

  const { isLoading, enrollmentList, enrollmentListCount } =
    useSelector(selectUser);
  const token = useSelector(selectUserToken);

  const toggleModal = () => {
    setOpen((open) => !open);
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  React.useEffect(() => {
    if (token) {
      dispatch(
        fetchEnrollmentListThunkAction(
          {
            limit: postsPerPage,
            offset: currentPage,
          },
          onSuccess,
          onError
        )
      );
    }
  }, [currentPage, token]);

  React.useEffect(() => {
    setUsers(enrollmentList);
  }, [enrollmentList, enrollmentList?.length]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: '50vh' }}>
              <CardHeader
                className={
                  width < 778
                    ? 'border-0'
                    : 'border-0 d-flex justify-content-between'
                }
              >
                <div
                  style={
                    width < 778
                      ? {
                          display: 'flex',
                          marginBottom: '0.5rem',
                          justifyContent: 'space-between',
                        }
                      : {
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.5rem',
                          justifyContent: 'start',
                        }
                  }
                >
                  <h3 className="mb-0">Course Enrollments</h3>
                </div>
              </CardHeader>
              {isLoading ? (
                <>
                  <>
                    <div
                      style={{
                        paddingTop: '7rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Loading...
                    </div>
                  </>
                </>
              ) : (
                <>
                  {error ? (
                    <>
                      <>
                        <div
                          style={{
                            paddingTop: '7rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {error}
                        </div>
                      </>
                    </>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" style={ {color: "black"} }>Course Title</th>
                            <th scope="col" style={ {color: "black"} }>Instructor Name</th>
                            <th scope="col" style={ {color: "black"} }>Student Name</th>
                            <th scope="col" style={ {color: "black"} }>Instructor Attended</th>
                            <th scope="col" style={ {color: "black"} }>Student Attended</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 &&
                            users.map((user) => {
                              return (
                                <tr key={user?.id}>
                                  <td scope="row">
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      {user?.course?.title}
                                    </Media>
                                  </td>
                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      {user?.instructor?.name}
                                    </Media>
                                  </td>

                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      {user?.user?.name}
                                    </Media>
                                  </td>

                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      {user?.instructor_attended ? (
                                        <Badge
                                          color="success"
                                          style={{ margin: '2px' }}
                                        >
                                          True
                                        </Badge>
                                      ) : (
                                        <Badge
                                          color="danger"
                                          style={{ margin: '2px' }}
                                        >
                                          False
                                        </Badge>
                                      )}
                                    </Media>
                                  </td>
                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      {user?.student_attended ? (
                                        <Badge
                                          color="success"
                                          style={{ margin: '2px' }}
                                        >
                                          True
                                        </Badge>
                                      ) : (
                                        <Badge
                                          color="danger"
                                          style={{ margin: '2px' }}
                                        >
                                          False
                                        </Badge>
                                      )}
                                    </Media>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && users.length === 0 && (
                        <>
                          <div
                            style={{
                              paddingTop: '7rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            No result found.
                          </div>
                        </>
                      )}
                      {users && users.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="...">
                            <NewPagination
                              totalRecords={enrollmentListCount}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
        {user && open && (
          <EnrolledCoursesModal
            toggleModal={toggleModal}
            isOpen={open}
            user={user}
          />
        )}
      </Container>
    </>
  );
};

export default EnrolledCourses;
