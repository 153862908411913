import axiosInstance from './AxiosInstance';

export const getImageListApi = () =>
  axiosInstance.get(`carousel`);

export const postCreateImageApi = (postData) =>
  axiosInstance.post('carousel', postData);

export const deleteImageApi = (id) =>
  axiosInstance.delete(`carousel/${id}`);
