import { instructorPaymentStatus } from 'Data/enums';
import { paymentStatus } from 'Data/enums';
import { enrollmentStatus } from 'Data/enums';
import React from 'react';
import { Modal, Row, Col, Badge } from 'reactstrap';

import '../../assets/css/post-modal.css';

const getFormattedDate = (scheduled_on, scheduled_time) => {
  const d1 = new Date(`${scheduled_on}T${scheduled_time[0]}:00Z`);
  const d2 = new Date(`${scheduled_on}T${scheduled_time[1]}:00Z`);
  const datestring = String(d1.getMonth()+1).padStart(2, "0")
    + "/" + String(d1.getDate()).padStart(2, "0") + "/" + d1.getFullYear();
  const timestring = String(d1.getHours()).padStart(2, "0") +
    ":" + String(d1.getMinutes()).padStart(2, "0") +
    " - " + String(d2.getHours()).padStart(2, "0") +
    ":" + String(d2.getMinutes()).padStart(2, "0");
  return {
    datestring,
    timestring
  }
}

const EnrolledCoursesModal = ({ toggleModal, isOpen, user }) => {
  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-lg"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h2 className="modal-title m-3" id="modal-title-default">
                Course Enrollement Details
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            
            <div className="modal-body post-modal-body m-4 modal-body-container">
              <h3>
                Course Details <i class="fas fa-book"></i>
              </h3>
              <div>
                {user?.course?.title && (
                  <p>
                    <h4 style={{ display: 'inline' }}>Title: </h4>
                    {user?.course?.title}
                  </p>
                )}
                {user?.course?.price ? (
                  <p>
                    <h4 style={{ display: 'inline' }}>Price: </h4>
                    ${user?.course?.price}
                  </p>
                ) : null}
                {user?.schedule_on && user?.schedule_time && (
                  <p>
                    <h4 style={{ display: 'inline' }}>Scheduled Date: </h4>
                    {getFormattedDate(user?.schedule_on, user?.schedule_time.split("-")).datestring}
                  </p>
                )}
                {user?.schedule_on && user?.schedule_time && (
                  <p>
                    <h4 style={{ display: 'inline' }}>Scheduled Time: </h4>
                    {getFormattedDate(user?.schedule_on, user?.schedule_time.split("-")).timestring}
                  </p>
                )}
                <p>
                  <h4 style={{ display: 'inline' }}>Enrollment Status: </h4>
                  <Badge color={enrollmentStatus[user?.status].color}>
                    {enrollmentStatus[user?.status].text}
                  </Badge>
                </p>
              </div>

              <hr />

              <div className="mt-4">
                <h3>
                  Student Details <i class="fas fa-user-graduate"></i>
                </h3>
                <div>
                  {user?.user?.name && (
                    <p>
                      <h4 style={{ display: 'inline' }}>Name: </h4>
                      {user?.user?.name}
                    </p>
                  )}

                  <p>
                    <h4 style={{ display: 'inline' }}>
                      Student Payment Status:{' '}
                    </h4>
                    <Badge color={paymentStatus[user?.course_payment_status].color}>
                      {paymentStatus[user?.course_payment_status].text}
                    </Badge>
                  </p>

                  <p>
                    <h4 style={{ display: 'inline' }}>Student Attended: </h4>
                    {user?.student_attended ? (
                      <Badge color="success">Yes</Badge>
                    ) : (
                      <Badge color="danger">No</Badge>
                    )}
                  </p>
                </div>
              </div>

              <hr />

              <div className="mt-4">
                <h3>
                  Instructor Details <i class="fas fa-user-clock"></i>
                </h3>
                <div>
                  {user?.instructor?.name && (
                    <p>
                      <h4 style={{ display: 'inline' }}>Name: </h4>
                      {user?.instructor?.name}
                    </p>
                  )}
                  <p>
                    <h4 style={{ display: 'inline' }}>
                      Instructor Payment Status:{' '}
                    </h4>
                    <Badge color={instructorPaymentStatus[user?.instructor_payment_status].color}>
                      {instructorPaymentStatus[user?.instructor_payment_status].text}
                    </Badge>
                  </p>
                  <p>
                    <h4 style={{ display: 'inline' }}>Instructor Attended: </h4>
                    {user?.instructor_attended ? (
                      <Badge color="success">Yes</Badge>
                    ) : (
                      <Badge color="danger">No</Badge>
                    )}
                  </p>
                </div>
              </div>

              <hr />

              <div className="mt-4">
                <h3>
                  Payment Details <i class="far fa-credit-card"></i>
                </h3>
                <div>
                  {user?.payment_date && (
                    <p>
                      <h4 style={{ display: 'inline' }}>Payment Date: </h4>
                      {getFormattedDate(user?.payment_date, ["00:00","00:01"]).datestring}
                    </p>
                  )}

                  {user?.payment_error && (
                    <p>
                      <h4 style={{ display: 'inline' }}>Payment Error: </h4>
                      {user?.payment_error}
                    </p>
                  )}

                  {user?.penalty_charges ? (
                    <p>
                      <h4 style={{ display: 'inline' }}>Penalty Charges: </h4>
                      ${user?.penalty_charges}
                    </p>
                  ) : null}

                  {user?.penalty_charges_paid ? (
                    <p>
                      <h4 style={{ display: 'inline' }}>
                        Penalty Charges Paid:{' '}
                      </h4>
                      {user?.penalty_charges_paid}
                    </p>
                  ) : null}
                  {user?.transfer_amount ? (
                    <p>
                      <h4 style={{ display: 'inline' }}>Transferred Amount: </h4>
                      ${user?.transfer_amount}
                    </p>
                  ) : null}
                  {user?.transfer_date && (
                    <p>
                      <h4 style={{ display: 'inline' }}>Transferred Date: </h4>
                      {getFormattedDate(user?.transfer_date, ["00:00","00:01"]).datestring}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default EnrolledCoursesModal;
