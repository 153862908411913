import Carousel from 'components/Carousel/CustomCarouselSlicker';
import Header from 'components/Headers/Header';
import AddImage from 'components/Modals/AddImage';
import AnnouncementModal from 'components/Modals/AnnouncementModal';
import VideoLinkModal from 'components/Modals/VideoLinkModal';
import useViewport from 'hooks/useViewport';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { deleteImageThunkAction } from 'redux/homeFeatures/actions';
import { deleteVideolinkThunkAction } from 'redux/homeFeatures/actions';
import { createAnnouncementThunkAction } from 'redux/homeFeatures/actions';
import { createVideolinkThunkAction } from 'redux/homeFeatures/actions';
import { fetchImagesThunkAction } from 'redux/homeFeatures/actions';
import { createImagesThunkAction } from 'redux/homeFeatures/actions';
import { deleteAnnouncementThunkAction } from 'redux/homeFeatures/actions';
import { fetchVideolinkThunkAction } from 'redux/homeFeatures/actions';
import { fetchAnnouncementThunkAction } from 'redux/homeFeatures/actions';
import { selectHomeFeatures } from 'redux/homeFeatures/selectors';
import Youtube from 'utils/getVideoThumbnail';
import '../components/Carousel/Carousel.css';

function HomeFeatures() {
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [announcementModelOpen, setAnnouncementModelOpen] = useState(false);
  const [videoLinkOpen, setVideoLinkOpen] = useState(false);

  const [images, setImages] = useState();
  const [announcements, setAnnouncements] = useState();
  const [videolinks, setVideolinks] = useState();

  const { width } = useViewport();
  const dispatch = useDispatch();
  const { announcementsList, videolinkList, imagesList } =
    useSelector(selectHomeFeatures);

  // fetches images, announcements, and videolinks from the database on initial render only

  React.useEffect(() => {
    dispatch(
      fetchAnnouncementThunkAction(
        () => {},
        (data) => {}
      )
    );
    dispatch(
      fetchImagesThunkAction(
        () => {},
        (data) => {}
      )
    );
    dispatch(
      fetchVideolinkThunkAction(
        () => {},
        (data) => {}
      )
    );
  }, []);

  // set Data after getting fom APIs

  React.useEffect(() => {
    setAnnouncements(announcementsList);
  }, [announcementsList, announcementsList.length]);

  React.useEffect(() => {
    setVideolinks(videolinkList);
  }, [videolinkList, videolinkList.length]);

  React.useEffect(() => {
    setImages(imagesList);
  }, [imagesList, imagesList.length]);

  // model image, announcement, videolink handlers

  const handleImageOpen = () => {
    setImageModalOpen(true);
  };

  const handleImageClose = () => {
    setImageModalOpen(false);
  };

  const handleAnnouncementOpen = () => {
    setAnnouncementModelOpen(true);
  };

  const handleVideoLinkOpen = () => {
    setVideoLinkOpen(true);
  };

  // remove image, announcement, videolink handlers

  const removeImageHandler = async (id) => {
    await dispatch(
      await deleteImageThunkAction(
        id,
        () => {},
        (error) => {}
      )
    );

    dispatch(
      fetchImagesThunkAction(
        () => {},
        (data) => {}
      )
    );
  };

  const removeAnnouncementHandler = async (id) => {
    await dispatch(
      await deleteAnnouncementThunkAction(
        id,
        () => {},
        (error) => {
          toast.error(error.message);
        }
      )
    );
    dispatch(
      fetchAnnouncementThunkAction(
        () => {},
        (data) => {}
      )
    );
  };

  const removeVideolinkHandler = async (id) => {
    await dispatch(
      await deleteVideolinkThunkAction(
        id,
        () => {},
        (error) => {}
      )
    );
    dispatch(
      fetchVideolinkThunkAction(
        () => {},
        (data) => {}
      )
    );
  };

  // submit image, announcement, videolink handlers

  const announcementSubmitHandler = async (data) => {
    await dispatch(
      await createAnnouncementThunkAction(
        {
          ...data,
        },
        () => {},
        (error) => {
          toast.error(error);
        }
      )
    );

    dispatch(
      fetchAnnouncementThunkAction(
        () => {},
        (data) => {}
      )
    );
  };

  const videolinkSubmitHandler = async (data) => {
    await dispatch(
      await createVideolinkThunkAction(
        {
          title: data.title,
          url: data.url,
          description: data.description,
          thumbnail_url: Youtube.thumb(data.url, ''),
        },
        () => {},
        (error) => {
          toast.error(error);
        }
      )
    );

    dispatch(
      fetchVideolinkThunkAction(
        () => {},
        (data) => {}
      )
    );
  };

  const imageSubmitHandler = async (data) => {
    const requestBodyFormData = new FormData();

    data?.map((item) => {
      return requestBodyFormData.append('image', item);
    });

    await dispatch(
      await createImagesThunkAction(
        requestBodyFormData,
        () => {},
        (error) => {
          toast.error(error);
        }
      )
    );

    dispatch(
      fetchImagesThunkAction(
        () => {},
        (data) => {}
      )
    );
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col ">
            <Card className="shadow  card_container h-auto pb-6">
              <CardHeader
                className={
                  width < 768
                    ? 'border-0'
                    : 'border-0 d-flex justify-content-between'
                }
              >
                <h3 className="mb-0">Home Features</h3>
              </CardHeader>
              <AddImage
                handleClose={handleImageClose}
                modalOpen={imageModalOpen}
                ImageHandler={imageSubmitHandler}
              />
              <CardBody>
                <div className="carousel">
                  <Row>
                    <Col
                      xs={12}
                      className="d-flex justify-content-between flex-wrap"
                    >
                      <h3>Images</h3>
                      <Button
                        color="success"
                        type="button"
                        onClick={handleImageOpen}
                      >
                        Add Images
                      </Button>
                    </Col>
                    <Col>
                      <Carousel
                        data={images}
                        removeData={removeImageHandler}
                        carouselType="image"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-9">
                    <Col
                      xs={12}
                      className="d-flex justify-content-between flex-wrap"
                    >
                      <h3>Announcements</h3>
                      <Button
                        color="success"
                        type="button"
                        onClick={handleAnnouncementOpen}
                      >
                        Add Announcement
                      </Button>
                    </Col>
                    <Col>
                      <Carousel
                        data={announcements}
                        removeData={removeAnnouncementHandler}
                        carouselType="announcement"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-8">
                    <Col
                      xs={12}
                      className="d-flex justify-content-between flex-wrap"
                    >
                      <h3>Video Link</h3>
                      <Button
                        color="success"
                        type="button"
                        onClick={handleVideoLinkOpen}
                      >
                        Add Video Link
                      </Button>
                    </Col>
                    <Col>
                      <Carousel
                        data={videolinks}
                        removeData={removeVideolinkHandler}
                        carouselType="videolink"
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
        {announcementModelOpen && (
          <AnnouncementModal
            toggleModal={() => setAnnouncementModelOpen(!announcementModelOpen)}
            isOpen={announcementModelOpen}
            onSubmitHandler={announcementSubmitHandler}
          />
        )}
        {videoLinkOpen && (
          <VideoLinkModal
            toggleModal={() => setVideoLinkOpen(!videoLinkOpen)}
            isOpen={videoLinkOpen}
            onSubmitHandler={videolinkSubmitHandler}
          />
        )}
      </Container>
    </>
  );
}

export default HomeFeatures;
