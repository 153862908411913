import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';

import { loggingOutUserThunkAction } from '../../redux/user/actions';
import { selectLoggedInUser } from 'redux/user/selectors';

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const user = useSelector(selectLoggedInUser);

  const navigateToHome = () => {
    history.replace('/auth/login');
  };

  const navigateToResetPasswordHandler = () => {
    history.push('/admin/resetpassword');
  };

  const logoutHandler = () => {
    dispatch(loggingOutUserThunkAction(navigateToHome));
  };
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <p
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {}
          </p>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require('../../assets/img/theme/admin-logo-green.png').default
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">Admin</span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">
                    Welcome!, {user?.name}
                  </h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  href="#pablo"
                  onClick={navigateToResetPasswordHandler}
                >
                  <img
                    width="20px"
                    alt="logout"
                    style={{ marginRight: '1rem' }}
                    height="20px"
                    src={
                      require('../../assets/img/theme/change-password.png')
                        .default
                    }
                  />
                  <span>Change Password</span>
                </DropdownItem>
                <DropdownItem href="#pablo" onClick={logoutHandler}>
                  <img
                    width="20px"
                    alt="logout"
                    style={{ marginRight: '1rem' }}
                    height="20px"
                    src={require('../../assets/img/theme/sign-out.png').default}
                  />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
