export const mailToUsers = [
  {
    id: 1,
    title: "All",
    value: "all",
  },
  {
    id: 2,
    title: "Instructors",
    value: "instructor",
  },
  {
    id: 3,
    title: "Students",
    value: "student",
  }
];