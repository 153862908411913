import {
  FETCH_ANNOUNCEMENT_FAILURE,
  FETCH_ANNOUNCEMENT_REQUEST,
  FETCH_ANNOUNCEMENT_SUCCESS,
  FETCH_IMAGE_FAILURE,
  FETCH_IMAGE_REQUEST,
  FETCH_IMAGE_SUCCESS,
  FETCH_VIDEOLINK_FAILURE,
  FETCH_VIDEOLINK_REQUEST,
  FETCH_VIDEOLINK_SUCCESS,
} from './types';

import toast from 'react-hot-toast';
import { getAnnouncementListApi } from 'services/AnnouncementServices';
import { postCreateAnnouncementApi } from 'services/AnnouncementServices';
import { deleteAnnouncmentApi } from 'services/AnnouncementServices';
import { getVideoLinkListApi } from 'services/VideoLinkServices';
import { postCreateVideoLinkApi } from 'services/VideoLinkServices';
import { deleteVideoLinkApi } from 'services/VideoLinkServices';
import { getImageListApi } from 'services/ImageServices';
import { postCreateImageApi } from 'services/ImageServices';
import { deleteImageApi } from 'services/ImageServices';

const fetchAnnouncementRequest = () => {
  return {
    type: FETCH_ANNOUNCEMENT_REQUEST,
  };
};

const fetchAnnouncementSuccess = (announcement) => {
  return {
    type: FETCH_ANNOUNCEMENT_SUCCESS,
    payload: announcement,
  };
};

const fetchAnnouncementFailure = (error) => {
  return {
    type: FETCH_ANNOUNCEMENT_FAILURE,
    payload: error,
  };
};

const fetchImagesRequest = () => {
  return {
    type: FETCH_IMAGE_REQUEST,
  };
};

const fetchImagesSuccess = (images) => {
  return {
    type: FETCH_IMAGE_SUCCESS,
    payload: images,
  };
};

const fetchImagesFailure = (error) => {
  return {
    type: FETCH_IMAGE_FAILURE,
    payload: error,
  };
};

const fetchVideolinkRequest = () => {
  return {
    type: FETCH_VIDEOLINK_REQUEST,
  };
};

const fetchVideolinkSuccess = (videolink) => {
  return {
    type: FETCH_VIDEOLINK_SUCCESS,
    payload: videolink,
  };
};

const fetchVideolinkFailure = (error) => {
  return {
    type: FETCH_VIDEOLINK_FAILURE,
    payload: error,
  };
};

export const fetchAnnouncementThunkAction = (
  onSuccess,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAnnouncementRequest());
      const { data } = await getAnnouncementListApi();
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchAnnouncementSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchAnnouncementFailure(error.message));
      onError(error.message);
    }
  };
};

export const createAnnouncementThunkAction = async (
  postData,
  onCreate,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      const promise = postCreateAnnouncementApi(postData);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Announcement created successfully.',
        error: 'Internal server Error.',
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onCreate();
    } catch (error) {
      onError(error.message);
    }
  };
};

export const deleteAnnouncementThunkAction = async (
  announcementId,
  onSuccess,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      const promise = deleteAnnouncmentApi(announcementId);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Announcement delete successfully.',
        error: 'Internal server Error.',
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onSuccess(announcementId);
    } catch (error) {
      toast.error(error.message);
      onError(error.message);
    }
  };
};

export const fetchImagesThunkAction = (onSuccess, onError = (args) => {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchImagesRequest());
      const { data } = await getImageListApi();

      if (data.success !== true) {
        throw new Error(data.message);
      }

      dispatch(fetchImagesSuccess(data.data));

      onSuccess();
    } catch (error) {
      dispatch(fetchImagesFailure(error.message));
      onError(error.message);
    }
  };
};

export const createImagesThunkAction = async (
  postData,
  onCreate,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      const promise = postCreateImageApi(postData);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Image added successfully.',
        error: 'Internal server Error.',
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onCreate();
    } catch (error) {
      onError(error.message);
    }
  };
};

export const deleteImageThunkAction = async (
  imageId,
  onSuccess,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      const promise = deleteImageApi(imageId);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Image deleted successfully.',
        error: 'Internal server Error.',
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onSuccess(imageId);
    } catch (error) {
      toast.error(error.message);
      onError(error.message);
    }
  };
};

export const fetchVideolinkThunkAction = (
  params,
  onSuccess,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchVideolinkRequest());
      const { data } = await getVideoLinkListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchVideolinkSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchVideolinkFailure(error.message));
      onError(error.message);
    }
  };
};

export const createVideolinkThunkAction = async (
  postData,
  onCreate,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      const promise = postCreateVideoLinkApi(postData);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'tutorial added successfully.',
        error: 'Internal server Error.',
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onCreate();
    } catch (error) {
      onError(error.message);
    }
  };
};

export const deleteVideolinkThunkAction = async (
  videolinkId,
  onSuccess,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      const promise = deleteVideoLinkApi(videolinkId);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Tutorial deleted successfully.',
        error: 'Internal server Error.',
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onSuccess(videolinkId);
    } catch (error) {
      toast.error(error.message);
      onError(error.message);
    }
  };
};
