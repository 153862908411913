/*eslint-disable*/
import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Badge,
  Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import Header from 'components/Headers/Header.jsx';
import Paginate from 'components/Pagination/Paginate';
import Search from 'components/Search/Search';
import UserModal from 'components/Modals/UserModal';

import useViewport from 'hooks/useViewport';

import { selectUserToken } from 'redux/user/selectors';
import { selectUser } from 'redux/user/selectors';
import { fetchSubscribedUsersThunkAction } from 'redux/user/actions';
import { setUserSearch } from 'redux/user/actions';
import { setUserFilter } from 'redux/user/actions';
import { useJsonToCsv } from 'react-json-csv';
import SelectSearch from 'components/SelectSearch/SelectSearch';
import { usersSelectSearchOptions } from 'Data/UserSelectSearch';

const SubscribedUsers = () => {
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [searchFilter, setSearchFilter] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const { width } = useViewport();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);

  const toggleModal = () => {
    setOpen((open) => !open);
  };

  const dispatch = useDispatch();

  const { subscribedUsers, isLoading, search } = useSelector(selectUser);
  const token = useSelector(selectUserToken);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    dispatch(setUserSearch(keyword));
  };

  const onFilterHandler = (searchItem) => {
    setCurrentPage(1);
    setSearchFilter(searchItem);
    dispatch(setUserFilter(searchItem?.value));
  };

  React.useEffect(() => {
    if (token) {
      dispatch(
        fetchSubscribedUsersThunkAction(
          search,
          searchFilter?.value,
          onSuccess,
          onError
        )
      );
    }
  }, [token, search, searchFilter]);

  React.useEffect(() => {
    const pageUsers = subscribedUsers.slice(
      (currentPage - 1) * postsPerPage,
      currentPage * postsPerPage
    );
    setUsers(pageUsers);
  }, [subscribedUsers, subscribedUsers.length, currentPage]);

  const paginate = (pageNum) => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage((prePage) => prePage + 1);

  const prevPage = () => setCurrentPage((prePage) => prePage - 1);

  const exportData = () => {
    const data = subscribedUsers.map((user) => {
      const obj = {
        name: `"${user.name}"`,
        email: `"${user.email}"`,
        about: `"${user.userInfo?.about}"`,
        roles: `"${user.roles.roles.toString()}"`,
        skills: `"${user.userInfo?.skills.toString(',')}"`,
        topics_to_teach: `"${user.userInfo?.topicsToTeach.toString()}"`,
        certificates: `"${user.userInfo?.certificates.toString()}"`,
        education_qualification: `"${user.userInfo?.educationQualification.toString()}"`,
        location: `"${user.userInfo?.location}"`,
      };
      return obj;
    });
    const fields = {
      name: 'Name',
      email: 'email',
      about: 'about',
      roles: 'Roles',
      skills: 'Skills',
      topics_to_teach: 'Topics to Teach',
      certificates: 'Certificates',
      education_qualification: 'Education Qualification',
      location: 'Location',
    };
    const { saveAsCsv } = useJsonToCsv();
    saveAsCsv({ data, fields, filename: 'edumame_user_data' });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: '50vh' }}>
              <CardHeader
                className={
                  width < 778
                    ? 'border-0'
                    : 'border-0 d-flex justify-content-between'
                }
              >
                <div
                  style={
                    width < 778
                      ? {
                          display: 'flex',
                          marginBottom: '0.5rem',
                          justifyContent: 'space-between',
                        }
                      : {
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.5rem',
                          justifyContent: 'start',
                        }
                  }
                >
                  <h3 className="mb-0">Subscribed Users</h3>
                  <Button
                    color="success"
                    data-dismiss="modal"
                    type="button"
                    onClick={exportData}
                  >
                    Export CSV
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  <SelectSearch
                    handleChange={onFilterHandler}
                    placeHolder="Search by user role"
                    options={usersSelectSearchOptions}
                    value={searchFilter}
                  />
                  <Search onSubmitHandler={onSearchHandler} search={search} />
                </div>
              </CardHeader>
              {isLoading ? (
                <>
                  <>
                    <div
                      style={{
                        paddingTop: '7rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Loading...
                    </div>
                  </>
                </>
              ) : (
                <>
                  {error ? (
                    <>
                      <>
                        <div
                          style={{
                            paddingTop: '7rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {error}
                        </div>
                      </>
                    </>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" style={ {color: "black"} }>User Id</th>
                            <th scope="col" style={ {color: "black"} }>Name</th>
                            <th scope="col" style={ {color: "black"} }>Email</th>
                            <th scope="col" style={ {color: "black"} }>Referral Count</th>
                            <th scope="col" style={ {color: "black"} }>Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 &&
                            users.map((user) => {
                              return (
                                <tr key={user?.id}>
                                  <th scope="row">
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {user?.id}
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>
                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      <div className="avatar rounded-circle mr-3">
                                        <img
                                          alt="..."
                                          src={
                                            user?.profileImage
                                              ? `${process.env.REACT_APP_CDN_URL}/${user?.profileImage}`
                                              : require('../assets/img/theme/user-logo.png')
                                                  .default
                                          }
                                        />
                                      </div>
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {user?.name}
                                        </span>
                                      </Media>
                                    </Media>
                                  </td>

                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {user?.email}
                                        </span>
                                      </Media>
                                    </Media>
                                  </td>

                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      <Media>
                                        {(
                                            <Badge
                                              color="dark"
                                              pill
                                              style={{ margin: '2px' }}
                                            >
                                              {user?.referredCount}
                                            </Badge>
                                          )}
                                      </Media>
                                    </Media>
                                  </td>

                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      <Media>
                                        {user?.roles.roles.length > 0 &&
                                          user?.roles.roles.map((role) => {
                                            return (
                                              <Badge
                                                color="success"
                                                style={{ margin: '2px' }}
                                              >
                                                {' '}
                                                {role}{' '}
                                              </Badge>
                                            );
                                          })}
                                      </Media>
                                    </Media>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && users.length === 0 && (
                        <>
                          <div
                            style={{
                              paddingTop: '7rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            No result found.
                          </div>
                        </>
                      )}
                      {users && users.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="...">
                            <Paginate
                              currentPage={currentPage}
                              postsPerPage={postsPerPage}
                              totalPosts={subscribedUsers.length}
                              paginate={paginate}
                              nextPage={nextPage}
                              prevPage={prevPage}
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
        {user && open && (
          <UserModal toggleModal={toggleModal} isOpen={open} user={user} />
        )}
      </Container>
    </>
  );
};

export default SubscribedUsers;
