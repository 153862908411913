import axiosInstance from './AxiosInstance';

export const getLoggedInUserApi = (userData) =>
  axiosInstance.post('admin/login', userData);

export const forgotPasswordUserApi = (userData) =>
  axiosInstance.post('forgot-password', userData);

export const resetPasswordUserApi = (userData) =>
  axiosInstance.post('reset-password', userData);

export const getSubscribedUsersApi = (userData) =>
  axiosInstance.get('user/list', {
    params: {
      search: userData.search,
      is_teacher: userData.is_teacher,
      is_student: userData.is_student,
    },
  });

export const postSendEmailApi = (userData) =>
  axiosInstance.post('admin/mail/sent', userData);

export const getEnrollmentListApi = (params) => axiosInstance.get('admin/enroll-course', {
  params : {
    offset: params.offset-1,
    limit: params.limit,
  }
});