import axiosInstance from './AxiosInstance';

export const getAnnouncementListApi = () =>
  axiosInstance.get(`announcement`);

export const postCreateAnnouncementApi = (postData) =>
  axiosInstance.post('announcement', postData);

export const deleteAnnouncmentApi = (id) =>
  axiosInstance.delete(`announcement/${id}`);
