import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import '../../assets/css/formMenu.css';

function FormMenu(props) {
  const { menuItems = [], selected, onSelectHandler, placeHolder } = props;

  return (
    <>
      <UncontrolledDropdown
        style={{
          width: '100%',
        }}
      >
        <DropdownToggle
          caret
          id="dropdownMenuButton"
          type="button"
          style={{
            width: '100%',
            color: 'gray',
          }}
          className="dropdown-toggle-menu"
          placeholder="Select"
        >
          {selected?.title || placeHolder}
        </DropdownToggle>

        <DropdownMenu
          aria-labelledby="dropdownMenuButton"
          style={{
            width: '100%',
          }}
        >
          {!selected && (
            <DropdownItem disabled onClick={() => onSelectHandler()}>
              {placeHolder}
            </DropdownItem>
          )}

          {menuItems.length > 0 &&
            menuItems.map((item, idx) => {
              return (
                <DropdownItem
                  key={item.id}
                  onClick={() => onSelectHandler(item)}
                >
                  {item.title}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default FormMenu;
