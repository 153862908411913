import React from 'react';
import { useLocation, Route, Switch } from 'react-router-dom';

import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import routes from 'routes.js';
import Authorization from 'hoc/Authorization';

import Error from 'pages/Error';
import ResetPasswordAdmin from 'pages/ResetPasswordAdmin';
import Users from 'pages/Users';

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const authenticateRoute = Authorization();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: '/admin/users',
          imgSrc: require('../assets/img/brand/edumame_title.png').default,
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Route
            path="/admin/users"
            exact
            component={authenticateRoute(Users)}
          />
          <Route
            path="/admin/resetpassword"
            exact
            component={authenticateRoute(ResetPasswordAdmin)}
          />
          <Route>
            <Error
              error="404 Page not found"
              message="we can not seem to find the page you are looking for."
            />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default Admin;
