import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';

import useViewport from 'hooks/useViewport';
import Header from 'components/Headers/Header';

import FormMenu from 'components/FormMenu/FormMenu';
import { NavLink } from 'react-router-dom';
import { mailToUsers } from 'Data/EmailTo';
import { sendEmailThunkAction } from 'redux/user/actions';

const NewCabinetPage = () => {
  const CHAR_LIMIT = 1024;

  const [mailTitle, setMailTitle] = useState('');
  const [mailBody, setMailBody] = useState('');
  const [mailTo, setMailTo] = useState('');

  const dispatch = useDispatch();
  const { width } = useViewport();

  const onSuccess = () => {
    setMailTitle('');
    setMailBody('');
    setMailTo('');
  };

  const onError = (error) => {};

  const onSelectMailToHandler = (item) => {
    setMailTo(item);
  };

  const resetHandler = () => {
    setMailTitle('');
    setMailBody('');
    setMailTo('');
  };

  const submitHadler = (e) => {
    e.preventDefault();

    const reqBody = {
      subject: mailTitle,
      html: mailBody,
      to: mailTo.value,
    };

    dispatch(sendEmailThunkAction(reqBody, onSuccess, onError));
  };

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div
            className="col"
            style={
              width < 768
                ? {}
                : {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
            }
          >
            <Card
              style={width < 850 ? { width: '100%' } : { width: '100%' }}
              className="bg-secondary shadow border-0"
            >
              <CardBody className="px-lg-5 py-lg-5">
                <div className="new_cabinet_page_title">
                  <h2> Send an Email</h2>
                </div>

                <Form
                  role="form"
                  onSubmit={submitHadler}
                  className="new_cabinet_form"
                >
                  <FormGroup>
                    <Row className="mt-5">
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cabinet-title"
                          >
                            Subject
                          </label>
                          <Input
                            className="form-control-alternative"
                            required
                            onChange={(e) => setMailTitle(e.target.value)}
                            value={mailTitle}
                            id="input-cabinet-title"
                            placeholder="Subject"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Send To
                          </label>
                          <FormMenu
                            menuItems={mailToUsers}
                            onSelectHandler={onSelectMailToHandler}
                            selected={mailTo}
                            placeHolder="Select Users"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cabinet-title"
                          >
                            Compose Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            required
                            onChange={(e) => {
                              setMailBody(e.target.value);
                            }}
                            maxLength={CHAR_LIMIT}
                            value={mailBody}
                            id="input-cabinet-title"
                            placeholder="Compose Email"
                            type="textarea"
                            rows="3"
                          />
                          <div
                            style={{ textAlign: 'right', fontSize: '0.8rem' }}
                          >
                            <i>
                              {mailBody.length} / {CHAR_LIMIT}
                            </i>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  <div className="text-center">
                    <Button color="success" type="submit">
                      Send
                    </Button>
                    <NavLink to="/admin/cabinets" replace>
                      <Button
                        style={{ backgroundColor: 'red', color: 'white' }}
                        type="button"
                        onClick={resetHandler}
                      >
                        Reset
                      </Button>
                    </NavLink>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default NewCabinetPage;
