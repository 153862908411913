import {
  DELETE_SUBSCRIBED_USER,
  FETCH_ENROLLMENTLIST_REQUEST,
  FETCH_ENROLLMENTLIST_SUCCESS,
  FETCH_SUBSCRIBED_USERS_FAILURE,
  FETCH_SUBSCRIBED_USERS_REQUEST,
  FETCH_SUBSCRIBED_USERS_SUCCESS,
  REFRESH_STATE,
  RESET_FORGET_PASSWORD_USER_EMAIL,
  RESET_IS_SIGNING,
  SET_FORGET_PASSWORD_USER_EMAIL,
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  SET_USER_FILTER,
  SET_USER_SEARCH,
} from './types';

import {
  forgotPasswordUserApi,
  getEnrollmentListApi,
  getLoggedInUserApi,
  getSubscribedUsersApi,
  postSendEmailApi,
  resetPasswordUserApi,
} from '../../services/UserServices';
import toast from 'react-hot-toast';
import { FETCH_ANNOUNCEMENT_FAILURE } from 'redux/homeFeatures/types';

export const fetchSubscribedUsersRequest = () => {
  return {
    type: FETCH_SUBSCRIBED_USERS_REQUEST,
  };
};

export const fetchSubscribedUsersSuccess = (users) => {
  return {
    type: FETCH_SUBSCRIBED_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchSubscribedUsersFailure = (error) => {
  return {
    type: FETCH_SUBSCRIBED_USERS_FAILURE,
    payload: error,
  };
};

export const fetchEnrollmentListRequest = () => {
  return {
    type: FETCH_ENROLLMENTLIST_REQUEST,
  };
};

export const fetchEnrollmentListSuccess = (users, totalCount) => {
  return {
    type: FETCH_ENROLLMENTLIST_SUCCESS,
    payload: { users, totalCount },
  };
};

export const fetchEnrollmentListFailure = (error) => {
  return {
    type: FETCH_ANNOUNCEMENT_FAILURE,
    payload: error,
  };
};

export const setLoggedInUser = ({ user, token }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { user, token },
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER,
  };
};

export const deleteSubscribedUser = (user) => {
  return {
    type: DELETE_SUBSCRIBED_USER,
    payload: user,
  };
};

export const refreshState = ({ token, user }) => ({
  type: REFRESH_STATE,
  payload: { token, user },
});

export const setForgetpasswordUserEmail = (email) => {
  return {
    type: SET_FORGET_PASSWORD_USER_EMAIL,
    payload: email,
  };
};

export const resetForgetpasswordUserEmail = (email) => {
  return {
    type: RESET_FORGET_PASSWORD_USER_EMAIL,
  };
};

export const setUserSearch = (search) => {
  return {
    type: SET_USER_SEARCH,
    payload: search,
  };
};

export const setUserFilter = (filter) => {
  return {
    type: SET_USER_FILTER,
    payload: filter,
  };
};

export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING,
  };
};

export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING,
  };
};

export const fetchLoginUserThunkAction = ({ email, password }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const { data } = await getLoggedInUserApi({ email, password });

      if (data.success !== true) {
        throw new Error(data.error);
      }
      toast.success('Logged in successfully.');
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('user', JSON.stringify(data.data.user));
      dispatch(
        setLoggedInUser({
          token: data.data.token,
          user: data.data.user,
        })
      );
      onSuccess();
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error.message);
    }
  };
};

export const loggingOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setLoggedOutUser());
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      toast.success('Logged out successfully.');
      onSuccess();
    } catch (error) {}
  };
};

export const forgetPasswordEmailThunkAction = (email) => {
  return async (dispatch) => {
    try {
      const { data } = await forgotPasswordUserApi({
        email,
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success('Please varify the OTP send to your email.');
      dispatch(setForgetpasswordUserEmail(email));
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const resetPasswordThunkAction = ({ password, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await resetPasswordUserApi({ email, password });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success('Your password has been reset successfully.');
      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const changePasswordThunkAction = ({ password, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await resetPasswordUserApi({ email, password });
      toast.success('Your password has been changed successfully.');
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      if (data.success !== true) {
        throw new Error(data.message);
      }

      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const fetchSubscribedUsersThunkAction = (
  search,
  filter,
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    try {
      const is_teacher = filter === 'instructor';
      const is_student = filter === 'student';
      dispatch(fetchSubscribedUsersRequest());
      const { data } = await getSubscribedUsersApi({
        search,
        is_teacher,
        is_student,
      });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchSubscribedUsersSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchSubscribedUsersFailure(error.message));
      onError(error.message);
    }
  };
};

export const fetchEnrollmentListThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchEnrollmentListRequest());
      const { data } = await getEnrollmentListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(
        fetchEnrollmentListSuccess(
          data?.data?.data,
          data?.data?.meta?.total_records
        )
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchEnrollmentListFailure(error.message));
      onError(error.message);
    }
  };
};

export const sendEmailThunkAction = (
  postData,
  onCreate,
  onError = (args) => {}
) => {
  return async (dispatch) => {
    try {
      const promise = postSendEmailApi(postData);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Email sent successfully.',
        error: 'Internal server Error.',
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      onCreate();
    } catch (error) {
      onError(error.message);
    }
  };
};
