import {
  FETCH_ANNOUNCEMENT_REQUEST,
  FETCH_ANNOUNCEMENT_SUCCESS,
  FETCH_ANNOUNCEMENT_FAILURE,
  FETCH_VIDEOLINK_REQUEST,
  FETCH_VIDEOLINK_SUCCESS,
  FETCH_VIDEOLINK_FAILURE,
  FETCH_IMAGE_SUCCESS,
  FETCH_IMAGE_FAILURE,
  FETCH_IMAGE_REQUEST,
} from './types';

const initialState = {
  isLoading: false,
  error: '',
  announcementsList: [],
  imagesList: [],
  videolinkList: [],
  search: '',
  filter: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ANNOUNCEMENT_REQUEST:
    case FETCH_VIDEOLINK_REQUEST:
    case FETCH_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        announcementsList: action.payload,
        error: '',
      };
    case FETCH_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        announcementsList: [],
        error: action.payload,
      };

    case FETCH_VIDEOLINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        videolinkList: action.payload,
        error: '',
      };

    case FETCH_VIDEOLINK_FAILURE:
      return {
        ...state,
        isLoading: false,
        videolinkList: [],
        error: action.payload,
      };
    case FETCH_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        imagesList: action.payload,
        error: '',
      };

    case FETCH_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        imagesList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
