import React from 'react';
import Slider from 'react-slick';
import Youtube from 'utils/getVideoThumbnail';

function LinkCard(props) {
  var title = props.data.title,
    subTitle = props.data.subTitle,
    videoURL = props.data.url,
    videolinkId = props.data.id;

  const video = Youtube.thumb(videoURL, '');

  return (
    <figure className="card-container video-container">
      <div className="card-link-announcement">
        <a
          href={videoURL}
          target="_blank"
          rel="noreferrer"
          className="video-link"
        >
          <img
            className="card-link-hero-img"
            src={video}
            alt={title}
            title={title}
          />
        </a>
        <div className="card-heading-container">
          <div className="card-title-container">
            <h3 className="card-title">{title}</h3>
            <p className="card-sub-title">{subTitle}</p>
          </div>
        </div>

        <div
          className="icon-container"
          onClick={() => props.removeItem(videolinkId)}
        >
          <i className="fas fa-trash-alt" />
        </div>
      </div>
    </figure>
  );
}

function ImageCard(props) {
  var image = props.data.link,
    imageAlt = props.data.id + 'image',
    imageId = props.data.id;

  return (
    <figure className="card-image-container">
      <div className="card-image">
        <img
          className="card-image-hero-img"
          src={process.env.REACT_APP_CDN_URL + '/' + image}
          alt={imageAlt}
        />
        <div
          className="icon-container"
          onClick={() => props.removeItem(imageId)}
        >
          <i className="fas fa-trash-alt image-icon" />
        </div>
      </div>
    </figure>
  );
}

function AnnouncementCard(props) {
  var title = props.data.title,
    text = props.data.description,
    announcementId = props.data.id;

  return (
    <figure className="card-container">
      <div className="card-link-announcement">
        <div className="card-text-heading-container">
          <div className="card-title-container">
            <h4 className="card-title">{title}</h4>
          </div>
        </div>
        <div className="card-text-container">
          <p className="card-text">{text}</p>
        </div>
        <div
          className="icon-container"
          onClick={() => props.removeItem(announcementId)}
        >
          <i className="fas fa-trash-alt" />
        </div>
      </div>
    </figure>
  );
}

const CustomCarouselSlicker = (props) => {
  const { data = [], removeData = () => {}, carouselType } = props;

  let newsTemplate;

  // ---------------------- ImageCard --------------------
  const imageSettings = {
    dots: true,
    accessibility: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    fade: true,
    infinite: data.length < 4 ? false : true,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // ---------------------- AnnouncementCard --------------------
  const settings = {
    dots: true,
    accessibility: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    infinite: data.length < 4 ? false : true,
    speed: 500,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (data.length > 0) {
    switch (carouselType) {
      case 'image':
        newsTemplate = data.map(function (item, index) {
          return (
            <div key={index}>
              <ImageCard data={item} removeItem={removeData} />
            </div>
          );
        });
        break;
      case 'announcement':
        newsTemplate = data.map(function (item, index) {
          return (
            <div key={index}>
              <AnnouncementCard data={item} removeItem={removeData} />
            </div>
          );
        });
        break;
      case 'videolink':
        newsTemplate = data.map(function (item, index) {
          return (
            <div key={index}>
              <LinkCard data={item} removeItem={removeData} />
            </div>
          );
        });
        break;
      default:
        newsTemplate = <p className="no-data">Please add some cards</p>;
        break;
    }
  } else {
    newsTemplate = <p className="no-data">Please add some cards</p>;
  }

  const isImageCarousel = carouselType === 'image' ? imageSettings : settings;

  return <Slider {...isImageCarousel}>{newsTemplate}</Slider>;
};

export default CustomCarouselSlicker;
