import EnrolledCourses from 'pages/EnrolledCourses';
import HomeFeatures from 'pages/HomeFeatures';
import SendEmail from 'pages/SendEmail';
import Users from 'pages/Users';

var routes = [
  {
    path: '/enrolledcourses',
    name: 'Enrolled Courses',
    icon: 'ni ni-paper-diploma text-green',
    component: EnrolledCourses,
    layout: '/admin',
  },
  {
    path: '/users',
    name: 'Subscribed Users',
    icon: 'ni ni-circle-08 text-green',
    component: Users,
    layout: '/admin',
  },
  {
    path: '/features',
    name: 'Home Features',
    icon: 'ni ni-bullet-list-67 text-green',
    component: HomeFeatures,
    layout: '/admin',
  },
  {
    path: '/sendemail',
    name: 'Send Email',
    icon: 'ni ni-email-83 text-green',
    component: SendEmail,
    layout: '/admin',
  },
];
export default routes;
