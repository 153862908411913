import React, { useState } from 'react';
import { Button, Modal, Row, Col, Input, Form, FormGroup } from 'reactstrap';
import '../../assets/css/modal.css';

const CHAR_LIMIT = 500;

const AnnouncementModal = ({ toggleModal, isOpen, onSubmitHandler }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h2 className="modal-title " id="modal-title-default">
                New Announcement
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body m-1">
              <Form
                role="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmitHandler({ title, description });
                  toggleModal(false);
                }}
              >
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-cabinet-title"
                  >
                    Title
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="title here"
                    type="text"
                    required
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-announcement-title"
                  >
                    Description
                  </label>
                  <Input
                    className="form-control-alternative"
                    required
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    maxLength={CHAR_LIMIT}
                    value={description}
                    id="input-announcement-title"
                    placeholder="Write your announcement here..."
                    type="textarea"
                    rows="3"
                  />
                  <div style={{ textAlign: 'right', fontSize: '0.8rem' }}>
                    <i>
                      {description.length} / {CHAR_LIMIT}
                    </i>
                  </div>
                </FormGroup>
                <div className="modal-footer d-flex justify-content-center">
                  <Button className="" color="secondary" type="submit">
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default AnnouncementModal;
