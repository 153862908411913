import React from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import Login from 'pages/Login.jsx';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import useViewport from 'hooks/useViewport';

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const { width } = useViewport();

  React.useEffect(() => {
    document.body.classList.add('bg-default');
    return () => {
      document.body.classList.remove('bg-default');
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header bg-gradient-success py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-3">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <img
                    alt="pikit logo"
                    style={
                      width > 768
                        ? {
                            width: '80%',
                            margin: 'auto',
                            display: 'flex ',
                          }
                        : {
                            width: '30%',
                            margin: 'auto',
                            display: 'flex',
                          }
                    }
                    src={
                      require('../assets/img/brand/edumame_title.png')
                        .default
                    }
                  />
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </div>
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Switch>
            <Route path="/auth/login" component={Login} />
            <Route path="/auth/forgotpassword" component={ForgotPassword} />
            <Route path="/auth/resetpassword" component={ResetPassword} />
            <Redirect from="*" to="/auth/login" />
          </Switch>
        </Row>
      </Container>
    </>
  );
};

export default Auth;
