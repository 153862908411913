export const usersSelectSearchOptions = [
  {
    id: 1,
    label: "All",
    value: 'all'
  },
  {
    id: 2,
    label: "Student",
    value: "student"
  },
  {
    id: 3,
    label: "Instructor",
    value: "instructor"
  }
];
