import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from 'App';
import store from './redux/store';
import { Toaster } from 'react-hot-toast';

dotenv.config();

ReactDOM.render(
  <Provider store={store}>
    <Toaster position="top-center" gutter={10} />
    <App />
  </Provider>,
  document.getElementById('root')
);
