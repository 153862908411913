import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Paginate = ({
  postsPerPage,
  totalPosts,
  paginate,
  nextPage,
  prevPage,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        {currentPage === pageNumbers[0] ? null : (
          <PaginationItem>
            <PaginationLink onClick={() => prevPage()} tabIndex="-1">
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
        )}
        {pageNumbers.map((num) => (
          <PaginationItem
            key={num}
            className={num === currentPage ? 'active' : ''}
          >
            <PaginationLink onClick={() => paginate(num)}>{num}</PaginationLink>
          </PaginationItem>
        ))}
        {currentPage === pageNumbers[pageNumbers.length - 1] ? null : (
          <PaginationItem>
            <PaginationLink onClick={() => nextPage()}>
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    </>
  );
};

export default Paginate;
