import axiosInstance from './AxiosInstance';

export const getVideoLinkListApi = () =>
  axiosInstance.get(`tutorial`);

export const postCreateVideoLinkApi = (postData) =>
  axiosInstance.post('tutorial', postData);

export const deleteVideoLinkApi = (id) =>
  axiosInstance.delete(`tutorial/${id}`);
