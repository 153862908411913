import {
  DELETE_SUBSCRIBED_USER,
  FETCH_SUBSCRIBED_USERS_FAILURE,
  FETCH_SUBSCRIBED_USERS_REQUEST,
  FETCH_SUBSCRIBED_USERS_SUCCESS,
  REFRESH_STATE,
  RESET_FORGET_PASSWORD_USER_EMAIL,
  RESET_IS_SIGNING,
  SET_FORGET_PASSWORD_USER_EMAIL,
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  SET_USER_SEARCH,
  SET_USER_FILTER,
  FETCH_ENROLLMENTLIST_REQUEST,
  FETCH_ENROLLMENTLIST_SUCCESS,
  FETCH_ENROLLMENTLIST_FAILURE,
} from './types';

const initialState = {
  loggedInUser: null,
  token: null,
  isLoading: false,
  error: '',
  isSigning: false,
  subscribedUsers: [],
  forgetEmail: '',
  search: '',
  filter: '',
  enrollmentList: [],
  enrollmentListCount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIBED_USERS_REQUEST:
    case FETCH_ENROLLMENTLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SUBSCRIBED_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscribedUsers: action.payload,
        error: '',
      };
    case FETCH_SUBSCRIBED_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        subscribedUsers: [],
        error: action.payload,
      };
    case FETCH_ENROLLMENTLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        enrollmentList: action.payload.users,
        enrollmentListCount: action.payload.totalCount,
        error: '',
      };
    case FETCH_ENROLLMENTLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        enrollmentList: [],
        enrollmentListCount: null,
        error: action.payload,
      };
    case DELETE_SUBSCRIBED_USER:
      return {
        ...state,
        subscribedUsers: action.payload,
      };
    case SET_LOGGEDIN_USER:
      return {
        ...state,
        loggedInUser: action.payload.user,
        token: action.payload.token,
        error: '',
        isSigning: false,
      };
    case SET_LOGGEDOUT_USER:
      return {
        ...state,

        users: [],
        error: '',
        loggedInUser: null,
        token: null,
        forgetEmail: '',
        search: '',
        filter: '',
      };
    case SET_FORGET_PASSWORD_USER_EMAIL:
      return {
        ...state,
        forgetEmail: action.payload,
      };
    case RESET_FORGET_PASSWORD_USER_EMAIL:
      return {
        ...state,
        forgetEmail: '',
      };
    case SET_USER_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_USER_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_IS_SIGNING:
      return {
        ...state,
        isSigning: true,
      };
    case RESET_IS_SIGNING:
      return {
        ...state,
        isSigning: false,
      };
    case REFRESH_STATE:
      return {
        ...state,
        token: action.payload.token,
        loggedInUser: action.payload.user,
      };
    default:
      return state;
  }
};

export default reducer;
