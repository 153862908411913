import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';
import { useDispatch, useSelector } from 'react-redux';

import { refreshState } from 'redux/user/actions';
import { selectUserToken } from 'redux/user/selectors';

const App = () => {
  const dispatch = useDispatch();

  const token = useSelector(selectUserToken) || localStorage.getItem('token');

  const refreshStateHandler = () => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user')) || null;
    dispatch(refreshState({ token, user }));
  };

  useEffect(() => {
    refreshStateHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [token]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          {token ? (
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
          ) : (
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          )}
          {token ? (
            <Redirect from="/" exact to="/admin/users" />
          ) : (
            <Redirect from="/" exact to="/auth/login" />
          )}

          <Route>
            {token ? (
              <Redirect to="/admin/notfound" />
            ) : (
              <Redirect to="/auth/notfound" />
            )}
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
