export const FETCH_IMAGES_REQUEST = 'FETCH_IMAGES_REQUEST'
export const FETCH_IMAGES_SUCCESS = 'FETCH_IMAGES_SUCCESS'
export const FETCH_IMAGES_FAILURE = 'FETCH_IMAGES_FAILURE'

export const FETCH_ANNOUNCEMENT_REQUEST = 'FETCH_ANNOUNCEMENT_REQUEST'
export const FETCH_ANNOUNCEMENT_SUCCESS = 'FETCH_ANNOUNCEMENT_SUCCESS'
export const FETCH_ANNOUNCEMENT_FAILURE = 'FETCH_ANNOUNCEMENT_FAILURE'

export const FETCH_VIDEOLINK_REQUEST = 'FETCH_VIDEOLINK_REQUEST'
export const FETCH_VIDEOLINK_SUCCESS = 'FETCH_VIDEOLINK_SUCCESS'
export const FETCH_VIDEOLINK_FAILURE = 'FETCH_VIDEOLINK_FAILURE'

export const FETCH_IMAGE_REQUEST = 'FETCH_IMAGE_REQUEST'
export const FETCH_IMAGE_SUCCESS = 'FETCH_IMAGE_SUCCESS'
export const FETCH_IMAGE_FAILURE = 'FETCH_IMAGE_FAILURE'
